export const AlertPenaltyTransactions = (function() {

    const HIDE_ALERT_COOKIE_NAME  = 'hide_pen_tr_alert';

    const exports = {};

    exports.init = function() {
        if (isNotCookieSet(HIDE_ALERT_COOKIE_NAME)) {
            // open nav dropdown
            if (!window._tl_drop_notifications) {
                window._tl_drop_notifications.doOpenToolTipNotify();
            } else {
                $('#notifications-trigger').dropdown('toggle');
            }

            // set cookie to prevent showing alert again
            setMessageClosedCookie();
        }
    }

    function isCookieSet(cookieName) {
        return document.cookie.indexOf(cookieName) !== -1;
    }

    function isNotCookieSet(cookieName) {
        return document.cookie.indexOf(cookieName) === -1;
    }

    function setMessageClosedCookie() {
        if (isCookieSet(HIDE_ALERT_COOKIE_NAME)) {
            return;
        }

        // show message again next day
        // cookie also clears on logout on the server
        const domain = window.this_site_domain ?? window.location.hostname;
        const date = new Date();

        date.setDate(date.getDate() + 1);

        document.cookie = `${HIDE_ALERT_COOKIE_NAME}=1; expires=${date.toUTCString()}; path=/; domain=${domain}`;
    }

    return exports;
})();